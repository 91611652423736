:root {
  --lightest-grey: #fafafa;
  --light-grey: #ededed;
  --med-grey: #d6d6d6;
  --primary: #3B6289;
  --primary-dark: #2e4d6b;
  --blue-faded: #e6f2ff;
  --success: #198754;
  --success-dark: #157347;
  --danger: #DC3545;
  --danger-dark: #BB2D3B;
  --yellow: #ffc107;
  --blue: #217ff2
}

body {
  color: #2d2d2d;
}

.btn.btn-primary {
  background-color: var(--primary);
}

.btn.btn-primary:hover {
  background-color: var(--primary-dark);
}

.btn.btn-success {
  background-color: var(--success);
}

.btn.btn-success:hover {
  background-color: var(--success-dark);
}

.btn.btn-danger {
  background-color: var(--danger);
}

.btn.btn-danger:hover {
  background-color: var(--danger-dark);
}

.calendar-list {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  margin-top: 20px;
}

.calendar-list .card {
  width: calc(25% - 15px);
}

.link { /* For buttons that look like links */
  background-color: transparent;
  border: 0px;
  text-decoration: underline;
  padding-left: 0px;
  padding-right: 0px;
}

.calendar-container {
  display: flex;
}

.day-view {
  width: 30%;
  padding: 20px;
  margin-top: 20px;
  overflow-y: auto;
}

.day-view .close-container {
  display: flex;
  justify-content: end;
}

.day-view .close-container i {
  font-size: 24px;
  cursor: pointer;
}

.accordion-item {
  margin-bottom: 10px;
}

.calendar {
  width: 100%;
  margin-top: 20px;
}

.calendar .day.selected {
  background-color: var(--blue-faded);
}

.month {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.week, .weekday {
  display: flex;
}

.week:last-child .day {
  border-bottom: 1px solid var(--light-grey);
}

.day:last-child {
  border-right: 1px solid var(--light-grey);
}

.day {
  border-left: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  width: 100%;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.day .marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 14px;
}

.day.passed {
  background-color: var(--lightest-grey);
}

.day.current .marker {
  background-color: green;
  color: white;
}

.weekday .day {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.prev-next-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.prev-next-container .month-name {
  font-size: 22px;
  color: var(--primary);
  font-weight: 500;
}

.prev, .next {
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.prev:hover, .next:hover {
  opacity: .7;
}

.prev i, .next i {
  position: relative;
  top: 1px;
}

.color-preview {
  margin-left: 5px;
  border-radius: 5px;
  width: 50px;
  height: 37px;
}

.react-time-picker {
  width: 100%;
}

.react-time-picker__wrapper {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  padding: .375rem .75rem;
}

.react-time-picker__inputGroup__input {
  width: calc(50% - 20px) !important;
}

.calendar-item-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.calendar-item-container .item, .calendar-item-container .items-more {
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-item-container .items-more {
  font-size: 12px;
  background-color: #f2f2f2;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-footer .btn-secondary {
  margin-right: 10px;
}

.items .delete {
  font-size: 11px;
}

.items .item {
  margin-bottom: 20px;
}

.items .item .item-header {
  padding: 10px;
}

.items .item .item-header.top-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.items .item .item-header.all-radius {
  border-radius: 5px;
}

.items .item .item-header a {
  padding: 10px;
}

.items .item .item-body {
  padding: 10px;
  border-left: 1px solid var(--med-grey);
  border-right: 1px solid var(--med-grey);
  border-bottom: 1px solid var(--med-grey);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.items .item .item-body .text-secondary {
  margin-bottom: 7px;
}

.items .item-name-and-time {
  width: 100%;
  margin-right: 10px;
  max-width: 90%;
}

.item-name-and-time .item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-name-and-time .item-time {
  white-space: nowrap;
  margin-left: 5px;
}


.link-list .link-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.link-list .link-item span, .link-list .link-item button {
  font-size: 13px;
  line-height: 16px;
}

.link-list .link-item button {
  padding-top: 3px;
}

.link-list .link-item:hover {
  background-color: #f2f2f2;
}

.loading-container {
  display: flex;
  align-items: center;
  padding: 20px;
}

.loading-container .spinner-border {
  margin-left: 15px;
  width: 25px;
  height: 25px;
  border: 2px solid #2D2D2D;
  border-right-color: transparent;
}

.resource-list ul li {
  list-style-type: none;
  padding-left: 0px;
}

.resource-list .folder-name {
  cursor: pointer;
}

.resource-list .folder-name .bi-caret-down {
  position: relative;
  top: 2px;
  left: 2px;
}

.resource-list .sub-folder {
  display: none;
  padding-left: 20px;
}

.resource-list .sub-folder.expanded {
  display: block;
}

.accordion {
  margin-top: 20px;
}

.accordion .accordion-item {
  margin-bottom: 10px;
}

.accordion > :not(:first-child) .accordion-header {
  border-top: 1px solid #dee2e6 !important;
  border-bottom-right-radius: 3px !important;
}

.accordion .accordion-button {
  border-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  box-shadow: none !important;
}

.accordion .accordion-body, .accordion .accordion-button {
  padding-top: 10px;
  padding-bottom: 10px;
}

.accordion .accordion-body {
  padding: 10px;
}

.accordion .accordion-body .bi-box-arrow-up-right {
  font-size: 14px;
}

.link-selection-accordion .accordion-button {
  background-color: white;
}

#item-accordion .accordion-button {
  padding-top: 8px !important;
}

.instructor-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 20px;
  background-color: var(--primary-dark);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
}

.instructor-nav i:hover {
  opacity: .7;
}

.display-days-container {
  text-transform: capitalize;
}

.display-days-container input {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}

.cyan, .blue, .red, .purple {
  color: white !important;
}

.cyan::after, .blue::after, .red::after, .purple::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}